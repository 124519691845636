import { int } from 'utrie/dist/types/Trie';

export interface ClientConfig {
  profileService: ProfileService;
  segment: Segment;
  datadog: Datadog;
  editor: EditorConfig;
}

export interface Segment {
  writeKey: string;
}

export interface Datadog {
  applicationId: string;
  clientToken: string;
  site: string;
  env: string;
  sessionSampleRate: string;
  sessionReplaySampleRate: string;
  enabled: boolean;
}

export interface ProfileService {
  auth0Domain: string;
  clientId: string;
  providerId: string;
}

export enum ContributionDisplayFormat {
  LONG_FORMAT = 'LONG_FORMAT',
  SHORT_FORMAT = 'SHORT_FORMAT',
}

export interface GraphConfig {
  visibleContributionCount: int;
  contributionDisplayFormat: ContributionDisplayFormat;
}

export interface EditorConfig {
  chainingEnabled: boolean;
  graph: GraphConfig;
}
