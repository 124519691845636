import { Button, Result, Space } from 'antd';
import { InboxOutlined } from '@ant-design/icons';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

export const NotFoundPage : FC = () => {
  const { t } = useTranslation();

  return (
    <Result
      icon={<InboxOutlined />}
      title={t('notfound.page.title.text')}
      subTitle={(
        <>
          {t('notFound.page.subTitle.text')}
        </>
            )}
      extra={(
        <Space wrap>
          <Button type="primary" onClick={() => window.location.assign('/')}>{t('notFound.page.button.home.text')}</Button>
        </Space>
            )}
    />
  );
};
