import React, {
  createContext, FC, PropsWithChildren, useEffect, useMemo, useRef, useState,
} from 'react';
import jwtDecode from 'jwt-decode';
import { useAuth0 } from '@auth0/auth0-react';
import sha1 from 'crypto-js/sha1';
import { UserData } from '../../dtos/user.dto';
import { axiosAuthClient } from './axiosAuthClient';
import { useAuthConfig } from './auth.properties.service';
import { ErrorPage } from '../../components/error-page/ErrorPage';
import { ProfileServiceMetadata } from './api/types';
import { useMetrics } from '../metrics/MetricsProvider';
import { useMonitoring } from '../monitoring/MonitoringProvider';
import { AuthMetricEvents } from './AuthMetricEvents';

export interface AuthContextData {
  user: UserData | null;
  signout: VoidFunction;
}

const AuthContext = createContext<AuthContextData>(null!);

const decodeToken = (token: string) => jwtDecode<any>(token);

// All the users create will be en email however this wasn't the case in the earlier days of profile service and some users were
// created with usernames not being ena email address. These are internal users, and we will treat them as such.
// If the username doesn't contain '@', we will mark them as internal.
const isInternalUser = (username: string, internalDomains: string[]): boolean => (
  !username.includes('@')
  || (internalDomains || []).some((internalDomain) => username.includes(`@${internalDomain}`))
);

export const AuthProvider = ({ children }: { children: React.ReactNode }) => {
  const { getAccessTokenSilently, logout } = useAuth0();
  const [user, setUser] = useState<UserData | null>(null);
  const [interceptorReady, setInterceptorReady] = useState<boolean>(false);
  const currentAccessTokenRef = useRef<string | null>(null);
  const authConfigResponse = useAuthConfig();
  const { metrics } = useMetrics();
  const { connector } = useMonitoring();

  const authTokenPrefix = 'Bearer';

  const createMetricEvents = (username: string, permissions: string[], internalUser: boolean, clientId: string) => {
    const hashedUsername = sha1(username || '').toString();
    metrics.onUserIdentified(hashedUsername, {
      clientId,
      permissions,
      internalUser,
    });
    // build version attached to user identify event backfills meaning when a new version is deployed,
    // all the past events updated to use the latest value. We don't want this. We will create an additional track event
    // for the build version so that it doesn't get overwritten.
    metrics.onTrackEvent(AuthMetricEvents.BuildVersionUsed, { buildVersion: process.env.BUILD_VERSION || 'unknown' });
  };

  const refreshToken = async () => {
    try {
      const accessToken = await getAccessTokenSilently();
      if (accessToken !== currentAccessTokenRef.current) {
        const decodedToken = decodeToken(accessToken);
        const { profile } = decodedToken['https://underwriteme.co.uk/app_metadata'];
        const profileServiceMetadata: ProfileServiceMetadata = JSON.parse(profile);
        const providerIds = (profileServiceMetadata.groups || []).flatMap((group) => (group.providers || []));
        const username = decodedToken['https://underwriteme.co.uk/username'];
        const userData: UserData = {
          id: decodedToken['https://underwriteme.co.uk/profile-id'],
          username,
          permissions: decodedToken['https://underwriteme.co.uk/permissions'],
          languageTag: profileServiceMetadata.languageTag,
          providerIds,
          allowAdminAccess: profileServiceMetadata.allowAdminAccess,
          samlUser: decodedToken['https://underwriteme.co.uk/authentication_type'] === 'SAML',
          internalUser: isInternalUser(username, authConfigResponse.loaded ? authConfigResponse.authConfig.internalDomains : []),
        };
        if (user?.id !== userData.id) {
          connector.setUser(userData.id, userData.username);
        }
        // Create new user identified event if the permissions are different
        if (user?.permissions !== userData.permissions) {
          createMetricEvents(
            userData.username,
            userData.permissions,
            userData.internalUser,
            authConfigResponse.authConfig.providerId,
          );
        }
        setUser(userData);
        currentAccessTokenRef.current = accessToken;
      }
      return accessToken;
    } catch (error) {
      console.error('Error refreshing token:', error);
      throw error;
    }
  };

  // Set up an interceptor to always use the most up-to-date access token
  useEffect(() => {
    if (authConfigResponse.loaded && !authConfigResponse.error && user && !interceptorReady) {
      axiosAuthClient.interceptors.request.use(
        async (config) => {
          const accessToken = await refreshToken();
          // These will never be null as axios will always provide them, but for typescript we need to check them (good practice)
          if (config && config.headers) {
            const newConfig = { ...config };
            newConfig.headers!.Authorization = `${authTokenPrefix} ${accessToken}`;
            return newConfig;
          }
          return config;
        },
        (error) => Promise.reject(error),
      );
      setInterceptorReady(true);
    }
    // The recommended way of working with axios interceptors is to eject the interceptor when the component dismounts which can be done
    // using the return values of the useEffect(). However, we found that Auth0Provider re-renders all of its children which causes
    // interceptor to be ejected and re-added, and some requests in between is being sent without auth header causing application errors.
    // Instead , we are setting up an interceptor once and never ejecting it. It will be destroyed when browser tab is closed or we navigate
    // away from the app.
    // return () => axiosAuthClient.interceptors.request.eject(interceptorId);
  }, [authConfigResponse, user?.username]);

  // Refresh user and token on first load of the component (e.g when refreshing the page)
  useEffect(() => {
    if (authConfigResponse.loaded && !authConfigResponse.error && !user) {
      refreshToken().then();
    }
  }, [authConfigResponse]);

  const value = useMemo(() => ({
    user,
    signout: () => {
      logout({ returnTo: `${window.location.protocol}//${window.location.host}/`, client_id: authConfigResponse.authConfig?.clientId });
      setUser(null);
    },
  }), [user, authConfigResponse]);

  if (authConfigResponse.error) {
    return (
      <ErrorPage />
    );
  } else if (!authConfigResponse.loaded || !user || !interceptorReady) {
    return (
      <div />
    );
  }

  if (user) {
    if (!user?.allowAdminAccess && !user.providerIds.includes(authConfigResponse.authConfig.providerId)) {
      setUser(null);
      logout({ returnTo: `${window.location.protocol}//${window.location.host}/`, client_id: authConfigResponse.authConfig?.clientId });
    }
  }

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => React.useContext(AuthContext);

export interface AuthGuardProps {
  permissions?: string[];
}

export const AuthGuard: FC<PropsWithChildren<AuthGuardProps>> = ({ children, permissions = [] }) => {
  const auth = useAuth();

  if (permissions.length && !auth?.user?.permissions.some((userPermission) => permissions.includes(userPermission))) {
    // TODO maybe worth adding a error page instead of forcing a signout
    return auth.signout();
  }
  return children as any;
};
