import React, { FC, Suspense, } from 'react';
import { ConfigProvider } from 'antd';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { BrowserRouter, Route, Routes, } from 'react-router-dom';
import { enableMapSet } from 'immer';

import { useAuth0, withAuthenticationRequired } from '@auth0/auth0-react';
import ErrorHandler from '../components/error-handler/ErrorHandler';
import Loader from '../components/loader/Loader';
import Layout from '../components/layout/Layout';
import Navigation from './Navigation';

import './App.less';
import { AuthGuard, AuthProvider } from '../services/auth/auth.service';
import { Permission } from '../dtos/user.dto';
import { ErrorPage } from '../components/error-page/ErrorPage';
import { MetricsProvider } from '../services/metrics/MetricsProvider';
import { CookieProvider } from '../services/cookie/CookieProvider';
import MetricsLoader from '../services/metrics/MetricsLoader';
import { useMonitoring } from '../services/monitoring/MonitoringProvider';
import MonitoringLoader from '../services/monitoring/MonitoringLoader';
import { NotFoundPage } from '../components/error-page/NotFoundPage';
import { axiosAuthClient } from '../services/auth/axiosAuthClient';
import NotFoundError from '../components/error-handler/NotFoundError';
import { useGetClientConfig } from '../services/monitoring/monitoring.service';
import { ContributionDisplayFormat, EditorConfig } from '../dtos/clientConfig.dto';

axiosAuthClient.interceptors.response.use(
  (response: any) => response,
  (error: any) => {
    // Throw a specific error type for 404. Can be extended further in the future if other error types need to have different behaviour
    if (error.response && error.response.status === 404) {
      return Promise.reject(new NotFoundError('404 - Resource not found'));
    }
    return Promise.reject(error);
  },
);

enableMapSet();

const Branches = React.lazy(() => import('./pages/branches/Branches'));
const Shell = React.lazy(() => import('./pages/shell/Shell'));
const History = React.lazy(() => import('./pages/history/History'));
const UserManagement = React.lazy(() => import('./pages/user-management/UserManagement'));
const ConsentManager = React.lazy(() => import('./pages/consent/ConsentManager'));
const Editor = React.lazy(() => import('./pages/editor/Editor'));
const Bucket = React.lazy(() => import('./pages/editor/bucket/Bucket'));
const Locale = React.lazy(() => import('./pages/editor/locale/Locale'));
const Question = React.lazy(() => import('./pages/editor/question/Question'));
const Options = React.lazy(() => import('./pages/editor/options/Options'));
const PriorEnquiryChannel = React.lazy(() => import('./pages/editor/prior-enquiry-channel/PriorEnquiryChannel'));
const Line = React.lazy(() => import('./pages/editor/line/Line'));
const HomeRouter = React.lazy(() => import('./HomeRouter'));

const DEFAULT_EDITOR_CONFIG: EditorConfig = {
  chainingEnabled: false,
  graph: {
    visibleContributionCount: 1,
    contributionDisplayFormat: ContributionDisplayFormat.LONG_FORMAT,
  },
};

const App: FC = (): React.ReactElement => {
  const queryResult = useGetClientConfig();
  const editorConfig: EditorConfig = queryResult.data?.editor || DEFAULT_EDITOR_CONFIG;
  const { connector } = useMonitoring();
  const {
    error, logout, isAuthenticated,
  } = useAuth0();

  const MIN_OPTION_LIST_SEARCH_ITEMS = 20;

  if (error || !isAuthenticated) {
    if (isAuthenticated) logout();
    return (<ErrorPage />);
  }

  return (
    <ErrorHandler
      onError={(errorVal, info) => {
        if (connector) {
          connector.addError(errorVal, info);
        }
        return true;
      }}
      errorFallback={ErrorPage}
      notFoundPage={<NotFoundPage />}
    >

      <ConfigProvider virtual={process.env.NODE_ENV !== 'development'}>
        <MetricsProvider>
          <AuthProvider>
            <CookieProvider>
              <MonitoringLoader />
              <MetricsLoader />
              <ReactQueryDevtools />
              <BrowserRouter>
                <Layout
                  className="history"
                >
                  <Navigation key="header" />
                  <Suspense
                    fallback={<Loader />}
                    key="content"
                  >
                    <Routes>
                      <Route path="/branches" element={<AuthGuard permissions={[Permission.CanUseEditor]}><Branches /></AuthGuard>} />
                      <Route path="/branches/:branchName" element={<AuthGuard permissions={[Permission.CanUseEditor]}><Branches /></AuthGuard>} />
                      <Route path="/branches/:branchName/:section" element={<AuthGuard permissions={[Permission.CanUseEditor]}><Branches /></AuthGuard>} />
                      <Route path="/history" element={<AuthGuard permissions={[Permission.CanUseEngineHistory]}><History /></AuthGuard>} />
                      <Route path="/shell" element={<AuthGuard permissions={[Permission.CanUseEngineShell]}><Shell /></AuthGuard>} />
                      <Route path="/user-management" element={<AuthGuard permissions={[Permission.CanReadUser]}><UserManagement /></AuthGuard>} />
                      <Route path="/editor/:branchName" element={<AuthGuard><Editor editorConfig={editorConfig} /></AuthGuard>}>
                        <Route path="bucket" element={<Bucket />} />
                        <Route path="bucket/:bucketName" element={<Bucket />} />
                        <Route path="option" element={<Options minOptionCountToEnableSearch={MIN_OPTION_LIST_SEARCH_ITEMS} />} />
                        <Route path="option/:optionListName" element={<Options minOptionCountToEnableSearch={MIN_OPTION_LIST_SEARCH_ITEMS} />} />
                        <Route path="question" element={<Question />} />
                        <Route path="question/:questionName" element={<Question />} />
                        <Route path="locale" element={<Locale />} />
                        <Route path="locale/:localeName" element={<Locale />} />
                        <Route path="prior-enquiry-channel" element={<PriorEnquiryChannel />} />
                        <Route path="prior-enquiry-channel/:priorEnquiryName" element={<PriorEnquiryChannel />} />
                        <Route path="enquiry-line" element={<Line graphConfig={editorConfig.graph} />} />
                        <Route path="enquiry-line/:lineName" element={<Line graphConfig={editorConfig.graph} />} />
                        <Route path="enquiry-line/:lineName/question" element={<Question />} />
                        <Route path="enquiry-line/:lineName/question/:questionName" element={<Question />} />
                        <Route path="wrap-up-line" element={<Line isWrapUp graphConfig={editorConfig.graph} />} />
                        <Route path="wrap-up-line/:lineName" element={<Line isWrapUp graphConfig={editorConfig.graph} />} />
                        <Route path="wrap-up-line/:lineName/question" element={<Question />} />
                        <Route path="wrap-up-line/:lineName/question/:questionName" element={<Question />} />
                        <Route path="root-enquiry-line" element={<Line isRoot graphConfig={editorConfig.graph} />} />
                        <Route path="root-enquiry-line/:lineName" element={<Line isRoot graphConfig={editorConfig.graph} />} />
                        <Route path="root-enquiry-line/:lineName/question" element={<Question />} />
                        <Route path="root-enquiry-line/:lineName/question/:questionName" element={<Question />} />
                      </Route>
                      <Route path="/consent-management" element={<AuthGuard><ConsentManager /></AuthGuard>} />
                      {/* Need to wrap in AuthGuard in order to force the AuthContext to be populated which is used by the HomeRouter */}
                      <Route
                        path="*"
                        element={<AuthGuard><HomeRouter /></AuthGuard>}
                      />
                    </Routes>
                  </Suspense>
                </Layout>
              </BrowserRouter>
            </CookieProvider>
          </AuthProvider>
        </MetricsProvider>
      </ConfigProvider>

    </ErrorHandler>
  );
};

export default withAuthenticationRequired(App, {});
