import { Button, Result, Space } from 'antd';
import { FrownOutlined } from '@ant-design/icons';
import React from 'react';

export const ErrorPage = () => (
  <Result
    icon={<FrownOutlined />}
    title="Sorry, an error has occurred"
    subTitle="Please try refreshing the page or go to homepage if the issue persists."
    extra={(
      <Space wrap>
        <Button type="primary" onClick={() => window.location.reload()}>Refresh</Button>
        <Button type="primary" onClick={() => window.location.assign('/')}>Home</Button>
      </Space>
            )}
  />
);
